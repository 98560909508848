import React from 'react';
import Footer from '../Footer/Footer';
import Hunter from '../Images/Hunter.jpg'
import Logan from '../Images/Logan.jpg'
import './landingPage.css'

const LandingPage = () => {
  return (
    <div id="landing">
      <div id="top">
        <img id="logo" src={require('../Images/GLDLogo.png')} alt="Good Luck Duck Logo"/>
        <h1 id="title">Good Luck Duck</h1>
      </div>
 

      <section className="section">
        <h2 id="whoHeader">Who are we?</h2>
        <p id="whoText">
          We are a company that got together with the idea of creating some awesome stuff. Our ideas include everything from mobile applications to games.
        </p>
      </section>

      <section className="section">
        <h2 id="teamHeader">Meet the Team</h2>
        <div id="teamBox">
          <div className="teamMember">
            <h3 className="name">Hunter Jones</h3>
            <h4 className="description">The idea man</h4>
            <img className="images" id="hunter" src={Hunter} alt="Hunter, the idea man" />
          </div>
          <div className="teamMember">
            <h3 className="name">Logan Ellis</h3>
            <h4 className="description" id="techGuyText">The tech guy</h4>
            <img className="images" id="logan" src={Logan} alt="Logan, the tech guy" />
          </div>
        </div>
        
      </section>
      <Footer />
    </div>
  )
}

export default LandingPage;
