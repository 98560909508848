import React, {Component} from 'react';
import './footer.css';

// This is the footer, later down the road I plan on adding a place to send your email to start collecting emails
class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p id="contact">Contact us: <a href="mailto: goodluckllc@gmail.com">goodluckllc@gmail.com</a></p>
        <p>© Good Luck Duck, LLC 2021</p>
      </div>
    );
  };
};

export default Footer;