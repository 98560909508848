import React from 'react';
import { Route, Switch } from 'react-router';

import './App.css';
import LandingPage from './LandingPage/LandingPage';


class App extends React.Component {
  render() {
    return (
      <Switch>
        <Route 
          exact
          path='/'
          component={LandingPage}
        />
      </Switch>
    )
  }
}

export default App;
